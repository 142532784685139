// Base 16 Paraiso
// Colors by Jan T. Sott
// Built with: https://github.com/chriskempson/base16-builder
$base16-color-00: #2f1e2e;
$base16-color-01: #41323f;
$base16-color-02: #4f424c;
$base16-color-03: #776e71;
$base16-color-04: #8d8687;
$base16-color-05: #a39e9b;
$base16-color-06: #b9b6b0;
$base16-color-07: #e7e9db;
$base16-color-08: #ef6155;
$base16-color-09: #f99b15;
$base16-color-0a: #fec418;
$base16-color-0b: #48b685;
$base16-color-0c: #5bc4bf;
$base16-color-0d: #06b6ef;
$base16-color-0e: #815ba4;
$base16-color-0f: #e96ba8;
