
$base-font-px: 18px;
$base-font-size: 1em;
$base-scale: $perfect-fourth;

$scale-4: modular-scale(-4, $base-font-size,  $base-scale);
$scale-3: modular-scale(-3, $base-font-size,  $base-scale);
$scale-2: modular-scale(-2, $base-font-size,  $base-scale);
$scale-1: modular-scale(-1, $base-font-size,  $base-scale);
$scale:   modular-scale(0,  $base-font-size, $base-scale);
$scale1:  modular-scale(1,  $base-font-size, $base-scale);
$scale2:  modular-scale(2,  $base-font-size, $base-scale);
$scale3:  modular-scale(3,  $base-font-size, $base-scale);
$scale4:  modular-scale(4,  $base-font-size, $base-scale);
$scale5:  modular-scale(5,  $base-font-size, $base-scale);
$scale6:  modular-scale(6,  $base-font-size, $base-scale);
$scale7:  modular-scale(7,  $base-font-size, $base-scale);
$scale8:  modular-scale(8,  $base-font-size, $base-scale);
$scale9:  modular-scale(9,  $base-font-size, $base-scale);
$scale10: modular-scale(10, $base-font-size,  $base-scale);
$scale11: modular-scale(11, $base-font-size,  $base-scale);
$scale12: modular-scale(12, $base-font-size,  $base-scale);

@import 'base16/base16-paraiso.scss';

$color-kinda-white: lighten($base16-color-07, 8%);

$sans-serif:  'Montserrat', sans-serif;
$serif:  'Merriweather', serif;
