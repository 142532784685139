.sidebar {
  width: 25%;
  background-attachment: scroll;
  background-position: 0 0;
  position: fixed;
  top: 0;
  bottom: 0;
  text-align: right;
  header {
    margin-top: 80%;
    padding: 0 $scale;
  }
}

.content-main {
  padding: $scale3;
}
.content-main,
footer {
  margin-left: 25%;
  max-width: 46em;
}
.previous-next {

  &.archive {
    text-align: center;
  }
  margin-bottom: $scale1;
}

