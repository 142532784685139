@import 'bourbon';
@import '_html5-doctor-reset-stylesheet';
@import '_variables.scss';

body {
  font-size: $base-font-px;
  font-family: $sans-serif;
  background-color: $color-kinda-white;
  color: $base16-color-01;
  line-height: 1.5;
  a {
    text-decoration: none;
  }
}
@media screen and (min-width: 58em) {
  body {
  font-size: $base-font-px + 4;
  }
}

p {
  margin: 0 0 $scale;
}

.content {
  font-family: $serif;
  h2, h3, h4, h5 {
    font-family: $sans-serif;
    margin-bottom: $scale-3;
  }
  p {
    line-height: 1.7;
  }
  ul {
    margin: $scale $scale1;
  }
  margin-bottom: $scale1;
}

.content-main {
  padding: $scale1;
  a {
    color: $base16-color-0b;
  }
  img {
    max-width: 100%;
  }
  h2 {
    font-size: $scale1;
  }
}

.posts-archive {
  h2 {
    color: $base16-color-02;
  }
  h3 {
    color: $base16-color-03;
    margin-top: $scale;
  }
  nav {
    margin-top: $scale;
  }
}
// Article.
.paginated, .post {
  margin-bottom: $scale2;
  .meta {
    margin-top: $scale-1;
  }
  header {
    p {
      color: $base16-color-03;
      font-size: $scale-1;
    }
    .entry-title {
      color: $base16-color-02;
      font-size: $scale3;
      line-height: $scale;
    }

    margin-bottom: $scale1;
  }
  .vcard, time {
    a {
      color: $base16-color-03;
    }
  }
  .nickname {
    display: none;
  }
}

.tag-listing {
  a {
    margin-right: $scale-4;
    padding: $scale-3;
    line-height: $scale4;
    border: 2px dotted $base16-color-0b;
  }
}

// The code that highlight.js is targeting.
code {
  font-size: $scale-1;
  margin: $scale 0;
}
// The code that is probably just in the body text.
:not(pre) > code {
  color: $base16-color-0e;
  font-size: $scale;
}

.sidebar {
  color: $base16-color-07;
  background-color: $base16-color-0f;
  text-align: center;
  padding: $scale-3 0;
  font-size: $scale;
  a {
    color: $color-kinda-white;
    text-decoration: none;
  }
  p {
    font-size: 85%;
  }
  h1 {
    font-size: $scale3;
  }
}

.inline-list {
  li {
    display: inline-block;
  }
}

.menu {
  margin: $scale;
  a {
    border-color: $base16-color-07;
  }
  font-size: $scale-1;
}

.button-link {
  border: 2px solid;
  border-radius: 2px;
  padding: $scale-4 $scale-2;
  text-transform: uppercase;
  text-decoration: none;
}

.footer-links {
  text-align: center;
  margin: $scale1;
  a {
    color: $base16-color-05;
    border-color: $base16-color-06;
  }
  .powered-by {
    margin-top: $scale;
    color: $base16-color-05;
    a {
      color: $base16-color-04;
    }
  }
}

a.tag-color {
  color: $color-kinda-white;
  padding: $scale-4 $scale-2;
  margin-right: $scale-4;
  margin-left: 0;
  font-size: $scale;
}

.tag-color-1 {
  background-color: $base16-color-08;
}

.tag-color-2 {
  background-color: $base16-color-0a;
}

.tag-color-3 {
  background-color: $base16-color-0c;
}

.tag-color-4 {
  background-color: $base16-color-0e;
}

.tag-color-5 {
  background-color: $base16-color-09;
}

.tag-color-6 {
  background-color: $base16-color-0b;
}

.tag-color-7 {
  background-color: $base16-color-0d;
}

.tag-color-8 {
  background-color: $base16-color-0f;
}

.previous-next {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  text-align: center;
  .title {
    color: $base16-color-03;
  }
}

.arrow {
  width: 0;
  height: 0;
  border: 0 solid transparent;
  display: inline-block;
  margin: 0 7px -2px;
}
.arrow-east {
  border-width: 8px 0 8px 12px;
  border-left-color: $base16-color-0b;
}
.arrow-west {
  border-width: 8px 12px 8px 0;
  border-right-color: $base16-color-0b;
}

@media screen and (min-width: 58em) {
  @import '_layout.scss';
}

.centered-image {
  display: block;
  margin: 15px auto;
}
.small {
  font-size: 60%;
  font-family: $sans-serif;
}

blockquote {
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 50px;
  padding-left: 15px;
  border-left: 3px solid #ccc;
}
